import React from 'react'
import '../styles/Header.css'
function Header() {
  return (
    <header>
        <div className="container">
            <div>
                <h1 className="brand-title">H.</h1>
            </div>
            <div className="menu-btn">
                <div className="btn-content">
                    <div>
                        MENU
                    </div>
                    <div>
                        CLOSE
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header