import React from 'react';

const Arrow = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H11M11 1V11M11 1L1 11" stroke="#F2F2F2" strokeWidth="2" />
        </svg>
    );
};

export default Arrow;
