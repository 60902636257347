import './App.css';
import { Suspense,lazy } from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

const Home = lazy(() => import('./Pages/Home'));
const Project = lazy(() => import('./Pages/Project'));
const Work = lazy(() => import('./Pages/Work'));


function App() {
  return (
    <Suspense fallback={''}>
        <Router>
            <div className="App">
            <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/work/' element={<Work />} />
                <Route path='/work/:id' element={<Project />} />
            </Routes>
            <Footer />
            </div>
        </Router>
    </Suspense>
  );
}

export default App;
