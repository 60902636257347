import React from 'react'
import '../styles/Footer.css'
function Footer() {
  return (
    <footer>
        <p>
            Code By Me
        </p>
    </footer>
  )
}

export default Footer