import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';
import Header from './Header';
import gsap, { Power4, Elastic } from 'gsap';
import Arrow from '../assets/images/Arrow';
import { HashLink } from 'react-router-hash-link';
import {Link} from 'react-router-dom'
function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    
    const openNavbar = () => {
        gsap.to('.navbar-container', {
            '--x1': 0,
            '--x2': '0%',
            duration: 1.2,
            delay: 0.2,
            ease: "expo.inOut"
        });
        gsap.to('header .menu-btn .btn-content div', {
            y: "-100%",
            duration: 1.2,
            delay: 0.2,
            ease: "expo.inOut"
        });
    };

    const closeNavbar = () => {
        gsap.to('.navbar-container', {
            '--x1': '0%',
            '--x2': '100%',
            duration: 1.2,
            delay: 0.2,
            ease: "expo.inOut",
            overwrite: true
        });
        gsap.to('header .menu-btn .btn-content div', {
            y: "0%",
            duration: 1.2,
            delay: 0.2,
            ease: "expo.inOut",
            overwrite: true
        });
        setTimeout(() => {
            gsap.to('.navbar-container', {
                '--x1': '100%',
                '--x2': '0%',
                duration: 0,
            });
        }, 1400);
    };

    const toggleNavbar = () => {
        if (isOpen) {
            closeNavbar();
        } else {
            openNavbar();
        }
        setIsOpen(!isOpen);
    };
    useEffect(() => {
    const navProfile = document.querySelector('.navbar-container .grid .profile')

        const navImgs = document.querySelectorAll('.navbar-container .grid img')
        navImgs.forEach((img, i) => {
            img.style.transform = `translate(${-i/1.25}rem,${i/1.25}rem)`
        })
    navProfile.onmousemove = (e) => {
        const axis = {
            x:e.clientX,
            y:e.clientY
        }
        const left = axis.x/navProfile.clientWidth
        const top = axis.y/navProfile.clientHeight
        navImgs.forEach((img,i) => {
            gsap.to(img, {
                left:left + Math.floor(Math.pow(10, -1) * i * 5) + 'rem',
                top:top + Math.floor(Math.pow(10, -1) * i * 5)  + 'rem'
            })
        })
    }
        const btn = document.querySelector("header .menu-btn");

        if (btn) {
            btn.addEventListener('click', toggleNavbar);
        }

        // Clean up event listener on component unmount
        return () => {
            if (btn) {
                btn.removeEventListener('click', toggleNavbar);
            }
        };
    }, [isOpen]);

    useEffect(() => {
        const megnaticButtons = document.querySelectorAll('.megnatic-buttons');
        megnaticButtons.forEach(button => {
            const content = button.querySelector('.megnatic-content');
            
            const activeMagnet = event => {
                const boundBox = button.getBoundingClientRect();
                const strength = 6;
                const textStrength = 8;
                const newX = ((event.clientX - boundBox.left) / button.offsetWidth) - 0.5;
                const newY = ((event.clientY - boundBox.top) / button.offsetHeight) - 0.5;
                
                gsap.to(button, {
                    duration: 1,
                    x: newX * strength,
                    y: newY * strength,
                    ease: Power4.easeOut
                });
                
                gsap.to(content, {
                    duration: 1,
                    x: newX * textStrength,
                    y: newY * textStrength,
                    ease: Power4.easeOut
                });
            };
            
            const resetMagnet = () => {
                gsap.to(button, {
                    duration: 1,
                    x: 0,
                    y: 0,
                    ease: Elastic.easeOut
                });
                
                gsap.to(content, {
                    duration: 1,
                    x: 0,
                    y: 0,
                    ease: Elastic.easeOut
                });
            };
            
            button.addEventListener('mousemove', activeMagnet);
            button.addEventListener('mouseleave', resetMagnet);
            
            // Cleanup event listeners on unmount
            return () => {
                button.removeEventListener('mousemove', activeMagnet);
                button.removeEventListener('mouseleave', resetMagnet);
            };
        });
    }, []);

    return (
        <>
            <Header />
            <div className="navbar-container">
                <div className="grid">
                    <div className="profile">
                        <img src={require('../assets/images/me.webp')} alt="Hussain Rafid" loading="lazy" />
                        <img src={require('../assets/images/me.webp')} alt="Hussain Rafid" loading="lazy" />
                        <img src={require('../assets/images/me.webp')} alt="Hussain Rafid" loading="lazy" />
                        <img src={require('../assets/images/me.webp')} alt="Hussain Rafid" loading="lazy" />
                    </div>
                    <div>
                        <nav>
                            <ul>
                                <li className="megnatic-buttons" onClick={() => {closeNavbar()}}>
                                    <HashLink smooth to="/">
                                        <div className="link-content megnatic-content">
                                            <div>Hello</div>
                                            <div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </li>
                                <li className="megnatic-buttons" onClick={() => {closeNavbar()}}>
                                    <HashLink smooth to="/#about">
                                        <div className="link-content megnatic-content">
                                            <div>About</div>
                                            <div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </li>
                                <li className="megnatic-buttons" onClick={() => {closeNavbar()}}>
                                    <Link to="/work">
                                        <div className="link-content megnatic-content">
                                            <div>Work</div>
                                            <div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="megnatic-buttons" onClick={() => {closeNavbar()}}>
                                    <HashLink smooth to="/#talk">
                                        <div className="link-content megnatic-content">
                                            <div>Talk</div>
                                            <div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </li>
                            </ul>
                            <div className="contacts">
                                <div className="contacts-links">
                                    <ul>
                                        <li>
                                            <a href="tel:07801040006">
                                                <div className="contact-link-wrapper">
                                                    <div className="link-wrapper-child">
                                                        <div>07801040006</div>
                                                        <div>
                                                            <Arrow/>
                                                        </div>
                                                    </div>
                                                    <div className="link-wrapper-child">
                                                        <div>07801040006</div>
                                                        <div>
                                                            <Arrow/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://gmail.com">
                                                <div className="contact-link-wrapper">
                                                    <div className="link-wrapper-child">
                                                        <div>info@hussainrafid.com</div>
                                                        <div>
                                                            <Arrow/>
                                                        </div>
                                                    </div>
                                                    <div className="link-wrapper-child">
                                                        <div>info@hussainrafid.com</div>
                                                        <div>
                                                            <Arrow/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-location">
                                    <p>Basrah - Iraq</p>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
